function ThankYouSpicy() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white px-4">
      <div className="text-center">
        <h1 className="text-7xl font-bold text-gray-900 mb-12">
          Thank you for testing our product!
        </h1>
        <p className="text-3xl text-gray-700 mb-8">
          We hope you enjoyed it! And we are curious about your feedback, which
          will help us make our product more enjoyable for you.
        </p>
        <button
          className="bg-red-500 text-white font-bold py-2 px-4 rounded-full w-full text-xl h-14"
          onClick={() => {
            window.open(
              "https://wuf5hkop4cw.typeform.com/to/LymQjOlF",
              "_blank"
            );
          }}
        >
          Give Feedback
        </button>
      </div>
    </div>
  );
}

export default ThankYouSpicy;
