import { useNavigate } from 'react-router-dom';

const HomeBible = () => {
  const navigate = useNavigate();
  return (
    <>
      <h1 className='text-3xl font-bold mt-2 mb-6'>Bible Apps</h1>
      <div className='grid grid-cols-1 gap-4'>
        <button
          className='bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow'
          onClick={() => navigate('/bible-pal')}>
          Bible Pal 😇
        </button>
        <button
          className='bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow'
          onClick={() => navigate('/bible-explainer')}>
          Bible Explainer 📖
        </button>
      </div>
    </>
  );
};

export default HomeBible;
