import { Outlet } from "react-router-dom";

function SpicesLayout() {
  return (
    <div className="flex justify-center items-center min-h-screen overflow-auto">
      <div className="w-full sm:max-w-xs mx-auto overflow-hidden">
        {/* Any common UI elements like header, nav, etc */}
        <Outlet /> {/* Nested routes will render here */}
      </div>
    </div>
  );
}

export default SpicesLayout;
