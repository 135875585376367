import React, { useEffect } from "react";
import { FireIcon } from "@heroicons/react/24/outline"; // or '@heroicons/react/solid' for solid icons
import { useNavigate } from "react-router-dom";

const backendURL = process.env.REACT_APP_BASE_URL;

const CreatingStoryPage = ({ parentPath }: { parentPath: string }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${backendURL}/api/spicy/stories/${localStorage.getItem(
            "storyId"
          )}/chapter-to-read/`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        } else {
          const data = await response.json();
          localStorage.setItem("question", data.question);
          localStorage.setItem("answersOptions", data.answers_options);
          localStorage.setItem("audioFile", data.tokenized_audio_url);

          const audioFile = localStorage.getItem("audioFile") || false;

          if (audioFile !== "null") {
            navigate(`${parentPath}listen/${localStorage.getItem("storyId")}/`);
          }

          console.log(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const intervalId = setInterval(fetchData, 10000);

    return () => clearInterval(intervalId);
  }, [navigate, parentPath]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white px-4 text-center">
      <h1 className="text-7xl font-bold mb-4">Creating your story</h1>
      <FireIcon className="h-28 w-28 text-red-500 mb-24" />
      {/* Custom Spinner */}
      <div
        className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
        role="status"
      >
        <span className="visually-hidden">
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </span>
      </div>
      <p className="mt-4">
        Please wait, we are baking your story, it will come out hot!...
      </p>
    </div>
  );
};

export default CreatingStoryPage;
