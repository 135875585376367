import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AudioPlayer from "../../components/AudioPlayer";

interface Option {
  value: string;
  label: string;
  audio?: string;
}

const QuestionSelection = ({
  title,
  options,
  nextPath,
  answerName,
  textBoxPlaceholder,
  additionalOnClickHandler,
  isMultipleChoice,
}: {
  title: string;
  options: Option[];
  nextPath: string;
  answerName: string;
  textBoxPlaceholder?: string;
  additionalOnClickHandler?: () => void;
  isMultipleChoice?: boolean;
}) => {
  const [selectedOption, setSelectedOption] = useState(
    localStorage.getItem(answerName) || ""
  );
  const [selectedOptionsMultiple, setSelectedOptionsMultiple] = useState(
    [] as string[]
  );
  useEffect(() => {
    console.log(
      `Component mounted with answerName: ${answerName} and selectedOption: ${selectedOption}`
    );
  }, [answerName, selectedOption]); // Add this at the top of your component

  const navigate = useNavigate();
  console.log(`Placeholder: ${textBoxPlaceholder} `);
  return (
    <div className="text-left flex flex-col items-center justify-center h-screen bg-white px-8">
      <h1 className="text-6xl font-bold text-gray-900">{title}</h1>
      {isMultipleChoice ? (
        <span className=" text-gray-900 text-left w-full">
          You can select multiple options
        </span>
      ) : null}
      <div className="text-left mb-8 w-full mt-6">
        {/* Fixed: Removed the hardcoded options and used the options prop */}
        {isMultipleChoice ? (
          <div className="flex flex-wrap">
            {options.map((option) => (
              <label>
                <div>
                  <button
                    name={answerName}
                    value={option.value}
                    onChange={() => setSelectedOption(option.value)}
                    key={option.value}
                    className={`flex justify-between border-2 border-black text-lg rounded-3xl m-2 p-2
                    ${
                      selectedOptionsMultiple.includes(option.value)
                        ? "bg-red-500 text-white"
                        : "bg-white text-black"
                    }`}
                    onClick={() => {
                      if (selectedOptionsMultiple.includes(option.value)) {
                        setSelectedOptionsMultiple(
                          selectedOptionsMultiple.filter(
                            (item) => item !== option.value
                          )
                        );
                      } else {
                        setSelectedOptionsMultiple([
                          ...selectedOptionsMultiple,
                          option.value,
                        ]);
                      }
                    }}
                  >
                    {option.label}
                  </button>
                </div>
              </label>
            ))}
          </div>
        ) : null}

        {!isMultipleChoice &&
          options.map((option) => (
            <label key={option.value} className="flex mb-4 justify-between">
              <div>
                <input
                  type="radio"
                  name={answerName}
                  value={option.value}
                  checked={selectedOption === option.value}
                  onChange={() => setSelectedOption(option.value)}
                  className="form-radio text-pink-500"
                />
                <span className="ml-2 text-2xl text-gray-700">
                  {option.label}
                </span>
              </div>
              <div>
                {option.audio ? (
                  <AudioPlayer audioSource={option.audio} />
                ) : null}
              </div>
            </label>
          ))}
        {options.length === 0 ? (
          <input
            type="text"
            name={answerName}
            className="border-2 border-gray-200 p-2 w-full text-xl"
            value={selectedOption}
            placeholder={textBoxPlaceholder}
            onChange={(e) => setSelectedOption(e.target.value)}
          />
        ) : null}
      </div>
      <button
        className="bg-red-500 text-white font-bold py-2 px-4 rounded-full w-full text-xl h-14 max-w-xs disabled:opacity-50 "
        disabled={
          isMultipleChoice
            ? selectedOptionsMultiple.length === 0
            : !selectedOption
        }
        onClick={() => {
          if (isMultipleChoice) {
            localStorage.setItem(
              answerName,
              JSON.stringify(selectedOptionsMultiple)
            );
          } else {
            localStorage.setItem(answerName, selectedOption);
            console.log(`Setting ${answerName} to ${selectedOption}`);
            setSelectedOption("");
          }
          if (additionalOnClickHandler) {
            additionalOnClickHandler();
          }

          navigate(nextPath);
        }}
      >
        Continue
      </button>
    </div>
  );
};

export default QuestionSelection;
