import React, { useState, useRef } from "react";
import { PlayIcon, PauseIcon } from "@heroicons/react/24/outline"; // For outline icons

function AudioPlayer({ audioSource }: { audioSource: string }) {
  const [isPlaying, setIsPlaying] = useState(false);
  // Explicitly defining the type of the ref as HTMLAudioElement
  const audioRef = useRef<HTMLAudioElement>(null);

  if (!audioSource) {
    return <div>No audio source provided</div>;
  }

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (audioRef.current) {
      if (!prevValue) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  };

  const handleAudioEnd = () => {
    setIsPlaying(false); // Change the state to false when the audio ends
  };

  return (
    <div>
      <audio
        ref={audioRef}
        src={audioSource}
        preload="auto"
        onEnded={handleAudioEnd}
      ></audio>
      <button onClick={togglePlayPause}>
        {isPlaying ? (
          <PauseIcon className="h-6 w-6" />
        ) : (
          <PlayIcon className="h-6 w-6" />
        )}
      </button>
    </div>
  );
}

export default AudioPlayer;
