import PlayingNowSVG from "../../images/spicy/playing_now.svg";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useNavigate } from "react-router-dom";

const ListenNowSpicy = ({ parentPath }: { parentPath: string }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center space-y-4 bg-white-100 min-h-screen p-4">
      <div className="text-lg font-bold italic pb-20">Now Playing</div>
      <img
        src={PlayingNowSVG} // Replace with your image path
        alt="Album Cover"
        className="w-64 h-64 rounded-lg shadow-lg"
      />
      <div className="text-3xl font-bold pt-20">Your Story</div>
      <div className="text-xl font-semibold">
        {localStorage.getItem("mood")}
      </div>
      <div className="w-full  pt-10">
        <AudioPlayer
          src={localStorage.getItem("audioFile") || ""}
          onPlay={(e) => console.log("onPlay")}
          onEnded={((e) => async () => {
            navigate(`${parentPath}thankyou/`);
          })()}
          // other props here
        />
      </div>
      {/* <div className="flex flex-col rounded-lg items-center bg-pink-100 min-h-screen max-w-md shadow-lg p-5 w-full ">
        <p className="flex text-left text-2xl font-bold">
          <MusicalNoteIcon className="" />
          Story Read
        </p>
        <p className="text-center mt-2">
          "Like, grrah, keep it a stack B**** move wock 'cause they know I got
          bands..."
        </p>
        <p className="text-center mt-2">
          "Like, grrah, keep it a stack B**** move wock 'cause they know I got
          bands..."
        </p>
        <p className="text-center mt-2">
          "Like, grrah, keep it a stack B**** move wock 'cause they know I got
          bands..."
        </p>
        <p className="text-center mt-2">
          "Like, grrah, keep it a stack B**** move wock 'cause they know I got
          bands..."
        </p>
        <p className="text-center mt-2">
          "Like, grrah, keep it a stack B**** move wock 'cause they know I got
          bands..."
        </p>
        <p className="text-center mt-2">
          "Like, grrah, keep it a stack B**** move wock 'cause they know I got
          bands..."
        </p>
      </div> */}
    </div>
  );
};

export default ListenNowSpicy;
