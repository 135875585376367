import { useState, useEffect, useRef } from 'react';
import ChatMessage from './ChatMessage';

export enum User {
  USER = 'User',
  GPT = 'GPT',
}

export interface ChatMessageData {
  message: String;
  user: User;
}

const defaultChatMessage: ChatMessageData = {
  user: User.GPT,
  message: "Hello, do you need guidance from the Bible? I'm here to help!",
};

const BiblePal = () => {
  const [userInput, setUserInput] = useState('');
  const [chatHistory, setChatHistory] = useState<ChatMessageData[]>([
    defaultChatMessage,
  ]);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const appendToChatHistory = (message: ChatMessageData) => {
    setChatHistory((prevHistory) => [...prevHistory, message]);
  };

  const postChatMessage = async (messages: ChatMessageData[]) => {
    try {
      const response = await fetch(
        'https://bible-pal-backend-64117d681c00.herokuapp.com/chat',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ messages }),
        }
      );
      const data = await response.json();
      appendToChatHistory({ message: data.gptResponse, user: User.GPT });
    } catch (error) {
      console.error('Failed to post user message:', error);
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the messages container whenever chatHistory changes
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  return (
    <>
      <div
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
        className='bg-white p-6'>
        <div className='flex flex-col flex-grow overflow-auto'>
          <div className='flex flex-col space-y-1.5 pb-6'>
            <h2 className='font-semibold text-lg tracking-tight'>
              Bible Pal 😇
            </h2>
          </div>

          <div
            style={{ minWidth: '100%' }}
            className='flex-grow overflow-auto'>
            {chatHistory.map((chatMessage, index) => (
              <ChatMessage
                key={index}
                chatMessage={chatMessage}
              />
            ))}
            {/* Invisible div at the end of the messages list to enable auto-scrolling */}
            <div ref={messagesEndRef} />
          </div>
        </div>

        <div className='pt-4'>
          <form className='flex items-center w-full'>
            <input
              className='flex-grow rounded-md border px-3 py-2 text-sm placeholder-gray-500 focus:outline-none'
              placeholder='Type your message'
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
            />
            <button
              className='ml-2 rounded-md bg-black text-white px-4 py-2'
              onClick={(e) => {
                e.preventDefault();
                if (userInput !== '') {
                  appendToChatHistory({ message: userInput, user: User.USER });
                  postChatMessage([
                    ...chatHistory,
                    { message: userInput, user: User.USER },
                  ]);
                  setUserInput('');
                }
              }}>
              Send
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default BiblePal;
