import "./index.css";
import TagManager from "react-gtm-module";

import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import SpicesLayout from "./pages/Spicy/LayoutSpicy";
import HomeSpicy from "./pages/Spicy/HomeSpicy";
import QuestionSelection from "./pages/Spicy/QuestionSelection";

import EthanAudio from "./sounds/spicy/EthanAudio.mp3";
import AvaAudio from "./sounds/spicy/AvaAudio.mp3";
import SerenaAudio from "./sounds/spicy/SerenaAudio.mp3";
import JulianAudio from "./sounds/spicy/JulianAudio.mp3";
import WilliamAudio from "./sounds/spicy/WilliamAudio.mp3";
import MatteoAudio from "./sounds/spicy/MatteoAudio.mp3";

import * as Sentry from "@sentry/react";
import CreatingStoryPage from "./pages/Spicy/CreatingStorySpicy";
import ListenNowSpicy from "./pages/Spicy/ListenNowSpicy";
import HomeBible from "./pages/Bible/HomeBible";
import ExplainationBot from "./pages/Bible/ExplainationBot";
import BiblePal from "./pages/Bible/BiblePal";
import { useEffect, useState } from "react";
import ThankYouSpicy from "./pages/Spicy/ThankYouSpicy";
import HomeDiscoverSpicy from "./pages/Spicy/HomeDiscoverSpicy";

const backendURL = process.env.REACT_APP_BASE_URL;

const PerspectiveRoute = ({ parentPath }: { parentPath: string }) => {
  return (
    <Route
      path="perspective"
      element={
        <QuestionSelection
          title="Through whose eyes will we experience your story?"
          options={[
            { value: "first-person", label: "First Person (I, me, my)" },
            { value: "second-person", label: "Second Person (You, your)" },
            { value: "third-person", label: "Third Person (He, she, they)" },
          ]}
          nextPath={`${parentPath}characters-type/`}
          answerName="perspective"
        />
      }
    />
  );
};

const CharacterRoute = ({ parentPath }: { parentPath: string }) => {
  return (
    <Route
      path="characters-type"
      element={
        <QuestionSelection
          title="Who are the main characters in your story?"
          options={[
            { value: "woman-woman", label: "Woman & Woman" },
            { value: "woman-man", label: "Woman & Man" },
            { value: "man-man", label: "Man & Man" },
          ]}
          nextPath={`${parentPath}question-gender/`}
          answerName="charactersType"
        />
      }
    />
  );
};

const GenderRoute = ({ parentPath }: { parentPath: string }) => {
  return (
    <Route
      path="question-gender"
      element={
        <QuestionSelection
          title="What is your gender?"
          options={[
            { value: "female", label: "Female" },
            { value: "male", label: "Male" },
            { value: "other", label: "Other" },
          ]}
          nextPath={`${parentPath}question-name/`}
          answerName="gender"
          textBoxPlaceholder="your name"
        />
      }
    />
  );
};

const QuestionNameRoute = ({
  parentPath,
  nextPath,
}: {
  parentPath: string;
  nextPath: string;
}) => {
  return (
    <Route
      path="question-name"
      element={
        <QuestionSelection
          title="What’s the name of your leading character?"
          options={[]}
          nextPath={`${parentPath}${nextPath}/`}
          answerName="yourName"
        />
      }
    />
  );
};

const DurationSelectionRoute = ({ parentPath }: { parentPath: string }) => {
  return (
    <Route
      path="story-duration"
      element={
        <QuestionSelection
          title="How long do you want your story to be?"
          options={[
            { value: "15-minutes", label: "15 minutes" },
            { value: "30-minutes", label: "30 minutes" },
            { value: "60-minutes", label: "60 minutes" },
          ]}
          nextPath={`${parentPath}create-story/`}
          answerName="storyDuration"
          additionalOnClickHandler={() => {
            const formData = {
              perspective: localStorage.getItem("perspective"),
              characters_type: localStorage.getItem("charactersType"),
              main_character_name: localStorage.getItem("yourName"),
              main_character_gender: localStorage.getItem("gender"),
              story_duration: localStorage.getItem("storyDuration"),
              mood_of_the_story: localStorage.getItem("mood"),
              main_character_attributes: localStorage.getItem("yourQualities"),
              second_character_id: localStorage.getItem("voiceSelection"),
              backdrop: localStorage.getItem("backdrop"),
              story_location: localStorage.getItem("storyLocation"),
            };
            console.log(backendURL);
            fetch(`${backendURL}/api/spicy/stories/`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formData),
            })
              .then((response) => response.json())
              .then((data) => {
                console.log("Success:", data);
                localStorage.setItem("storyId", data.id);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
            console.log("Voice selected");
          }}
        />
      }
    />
  );
};

const VoiceSelectionRoute = ({ parentPath }: { parentPath: string }) => {
  return (
    <Route
      path="question-voice"
      element={
        <QuestionSelection
          title="Select a voice that makes you 💦🔥"
          options={[
            {
              value: "ava",
              label: "Ava",
              audio: AvaAudio,
            },

            {
              value: "serena",
              label: "Serena",
              audio: SerenaAudio,
            },
            {
              value: "ethan",
              label: "Ethan",
              audio: EthanAudio,
            },
            {
              value: "julian",
              label: "Julian",
              audio: JulianAudio,
            },
            { value: "william", label: "William", audio: WilliamAudio },
            { value: "matteo", label: "Matteo", audio: MatteoAudio },
          ]}
          nextPath={`${parentPath}story-duration/`}
          answerName="voiceSelection"
        />
      }
    />
  );
};

function GetSpicyRoutes1() {
  const parentPath = "/spices/1/";

  return (
    <Route path={parentPath} element={<SpicesLayout />}>
      <Route
        index
        element={<HomeSpicy nextLink={`${parentPath}backdrop-story/`} />}
      />
      <Route path="thankyou" element={<ThankYouSpicy />} />
      <Route
        path="backdrop-story"
        element={
          <QuestionSelection
            title="What’s the backdrop of your story?"
            options={[
              { value: "realistic-fiction", label: "Realistic Fiction" },
              { value: "fantasy", label: "Fantasy" },
            ]}
            nextPath={`${parentPath}question-mood/`}
            answerName="backdrop"
          />
        }
      />
      <Route
        path="question-mood"
        element={
          <QuestionSelection
            title="What’s the vibe of your story?"
            options={[
              { value: "romantic", label: "Romantic 🌹" },
              { value: "spicy", label: "Spicy 🌶" },
            ]}
            nextPath={`${parentPath}perspective/`}
            answerName="mood"
          />
        }
      />
      {PerspectiveRoute({ parentPath })}
      {CharacterRoute({ parentPath })}
      {GenderRoute({ parentPath })}
      {QuestionNameRoute({ parentPath, nextPath: "story-location" })}
      <Route
        path="story-location"
        element={
          <QuestionSelection
            title="Where does your story unfold?"
            options={[]}
            nextPath={`${parentPath}question-qualities/`}
            answerName="storyLocation"
          />
        }
      />
      <Route
        path="question-qualities"
        element={
          <QuestionSelection
            title="What makes your character tick?"
            options={[]}
            nextPath={`${parentPath}question-voice/`}
            answerName="yourQualities"
            textBoxPlaceholder="e.g. I have long and curly hair"
          />
        }
      />
      {DurationSelectionRoute({ parentPath })}
      {VoiceSelectionRoute({ parentPath })}
      <Route
        path="create-story"
        element={<CreatingStoryPage parentPath={parentPath} />}
      />
      <Route
        path="listen/:storyId/"
        element={<ListenNowSpicy parentPath={parentPath} />}
      />
      <Route path="user-prompt/" element={<UserPrompt />} />
    </Route>
  );
}

function GetSpicyRoutes2() {
  const parentPath = "/spices/2/";

  return (
    <Route path={parentPath} element={<SpicesLayout />}>
      <Route
        index
        element={
          <HomeDiscoverSpicy
            nextLink={`${parentPath}perspective`}
            answerName="storyType"
          />
        }
      />
      {PerspectiveRoute({ parentPath })}
      {CharacterRoute({ parentPath })}
      {GenderRoute({ parentPath })}
      {QuestionNameRoute({ parentPath, nextPath: "character-ticks-multi" })}
      {VoiceSelectionRoute({ parentPath })}
      {DurationSelectionRoute({ parentPath })}
      <Route
        path="character-ticks-multi"
        element={
          <QuestionSelection
            title="What makes your character tick? 🔥💦"
            options={[
              { value: "confident", label: "Confident" },
              { value: "sensual", label: "Sensual" },
              { value: "adventurous", label: "Adventurous" },
              { value: "mysterious", label: "Mysterious" },
              { value: "witty", label: "Witty" },
              { value: "charming", label: "Charming" },
              { value: "playful", label: "Playful" },
              { value: "passionate", label: "Passionate" },
              { value: "spontaneous", label: "Spontaneous" },
              { value: "intelligent", label: "Intelligent" },
              { value: "jealous", label: "Jealous" },
              { value: "dominant", label: "Dominant" },
            ]}
            nextPath={`${parentPath}question-voice/`}
            answerName="yourQualities"
            isMultipleChoice={true}
          />
        }
      />

      <Route path="thankyou" element={<ThankYouSpicy />} />

      <Route
        path="create-story"
        element={<CreatingStoryPage parentPath={parentPath} />}
      />
      <Route
        path="listen/:storyId/"
        element={<ListenNowSpicy parentPath={parentPath} />}
      />
      <Route path="user-prompt/" element={<UserPrompt />} />
    </Route>
  );
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {GetSpicyRoutes1()}
          {GetSpicyRoutes2()}
          <Route path="bible-home" element={<HomeBible />} />
          <Route path="bible-pal" element={<BiblePal />} />
          <Route path="bible-explainer" element={<ExplainationBot />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

interface Option {
  value: string;
  label: string;
}
interface Option {
  value: string;
  label: string;
}

function UserPrompt() {
  const [title, setTitle] = useState<string>("");
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    const storedTitle = localStorage.getItem("question") || "";
    const storedOptions: Option[] =
      localStorage
        .getItem("answersOptions")
        ?.split(",")
        .slice(0, 3)
        .map((option) => ({
          value: option,
          label: option,
        })) || [];
    setTitle(storedTitle);
    setOptions(storedOptions);
  }, []); // The empty array ensures this effect runs once on mount

  return (
    <QuestionSelection
      title={title}
      options={options}
      nextPath="/spices/create-story/"
      answerName="userAnswerToPrompt"
      additionalOnClickHandler={() => {
        const storyId = localStorage.getItem("storyId");
        const formData = {
          userAnswer: localStorage.getItem("userAnswerToPrompt"),
        };
        console.log(backendURL);
        fetch(`${backendURL}/api/spicy/stories/${storyId}/chapter-to-read/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Success:", data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
        console.log("User prompt answered");
      }}
    />
  );
}

Sentry.init({
  // dsn: "https://6228618dce347ddb4d7a74c0fa3c332a@o4504362673504256.ingest.sentry.io/4506734367670272",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const tagManagerArgs = {
  gtmId: "GTM-N24QGW6L",
};

TagManager.initialize(tagManagerArgs);

export default App;
