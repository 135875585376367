import React, { useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import IntroAudio from "../../sounds/spicy/IntroAudio.mp3";
import {
  FireIcon,
  HeartIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline"; // For the outline version
import { useNavigate } from "react-router-dom";

type Story = {
  id: string;
  title: string;
  category: "Spicy" | "Romantic";
  color: string;
};

const stories: Story[] = [
  {
    id: "deep-desires-underwater",
    title: "Deep Desires Underwater",
    category: "Spicy",
    color: "pink-100",
  },
  {
    id: "bare-desires-on-the-beach",
    title: "Bare Desires on the Beach",
    category: "Romantic",
    color: "blue-500",
  },
  {
    id: "sweaty-encounters-in-the-gym",
    title: "Sweaty encounters at the gym",
    category: "Romantic",
    color: "pink-100",
  },
  // Add more stories here...
];

const HomeDiscoverSpicy = ({
  nextLink,
  answerName,
}: {
  nextLink: string;
  answerName: string;
}) => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState<"All" | "Spicy" | "Romantic">("All");

  const filteredStories = stories.filter((story) =>
    filter === "All" ? true : story.category === filter
  );

  return (
    <div className="p-4">
      <div>
        <h1 className="text-7xl font-bold text-gray-900 mb-12 text-left">
          Discover
        </h1>
        <hr className="border-t-4 border-red-500 my-4 w-1/2 mx-0 " />
      </div>
      <div className="flex justify-between mb-4">
        <button
          className="flex text-white bg-black rounded-3xl p-2 px-4 text-l pl-5 pr-5"
          onClick={() => setFilter("All")}
        >
          <TableCellsIcon className="h-6 w-6 mr-2" />
          All
        </button>
        <button
          className="flex text-white bg-red-500 rounded-3xl p-2 px-4 text-l pl-5 pr-5"
          onClick={() => setFilter("Spicy")}
        >
          <FireIcon className="h-6 w-6 mr-2" /> Spicy
        </button>
        <button
          className="flex text-white bg-red-500 rounded-3xl p-2 px-4 text-l pl-5 pr-5"
          onClick={() => setFilter("Romantic")}
        >
          <HeartIcon className="h-6 w-6 mr-2" />
          Romantic
        </button>
      </div>
      <div className="text-center mt-12">
        <h3 className="text-left">Trending</h3>
      </div>
      <div className="grid grid-cols-2 gap-4 m-10">
        {filteredStories.map((story) => (
          <div className={`flex bg-${story.color} rounded-xl`}>
            <button
              key={story.id}
              className="p-4 text-left text-black w-full  text-xl"
              onClick={() => {
                localStorage.setItem(answerName, story.id);
                navigate(nextLink);
              }}
            >
              {story.title} <br />
              🎧
            </button>
          </div>
        ))}
      </div>
      <button
        className="bg-red-500 text-white font-bold py-2 px-4 rounded-full w-full text-xl h-14"
        onClick={() => {
          localStorage.setItem(answerName, "customStory");
          navigate(nextLink);
        }}
      >
        Create your own story
      </button>
      <div className="w-full  pt-10">
        <AudioPlayer
          src={IntroAudio}
          // other props here
        />
      </div>
    </div>
  );
};

export default HomeDiscoverSpicy;
