import { useNavigate } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import IntroAudio from "../../sounds/spicy/IntroAudio.mp3";

const HomeSpicy = ({ nextLink }: { nextLink: string }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white px-4">
      <div className="text-center">
        <h1 className="text-7xl font-bold text-gray-900 mb-12">
          Discover sexy stories for every mood
        </h1>
        <p className="text-3xl text-gray-700 mb-8">
          Take time for your desires. Discover your lust with our intimate audio
          stories.
        </p>
        <button
          className="bg-red-500 text-white font-bold py-2 px-4 rounded-full w-full text-xl h-14"
          onClick={() => {
            localStorage.clear();
            navigate(nextLink); // Update the navigate function call
          }}
        >
          Create your story
        </button>
      </div>
      <div className="w-full  pt-10">
        <AudioPlayer
          src={IntroAudio}
          // other props here
        />
      </div>
    </div>
  );
};

export default HomeSpicy;
